// Scroll Magic
function scrolltime() {
    //DEV MODE
    var DEVMODE = false;
    //Scroll Magic Controller
    var sm_cont = new ScrollMagic.Controller({});

    //Fade In Universal
    var sm_e_fade = $(".scroll-fade");
    if (sm_e_fade.length) {
        sm_e_fade.each(function() {
            var tl_u = new TimelineMax({});
            tl_u.add(TweenMax.staggerFromTo(sm_e_fade, 0.6, { opacity: "0" }, { opacity: "1" }, 0.3));
            var sm_s_u = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", offset: 300 })
                .setTween(tl_u)
                .addTo(sm_cont);
            if (DEVMODE) { sm_s_u.addIndicators({ name: "scroll-fade", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }

    //Bounce In Universal
    var sm_e_bounce = $(".scroll-bounce");
    if (sm_e_bounce.length) {
        sm_e_bounce.each(function() {
            var sm_s_bounce = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", offset: 200 })
                .setTween(TweenMax.staggerFromTo(sm_e_bounce.children("span"), 1, { top: "-300px" }, { top: "0px", ease: Bounce.easeOut }, 0))
                .addTo(sm_cont);
            if (DEVMODE) { sm_s_bounce.addIndicators({ name: "scroll-bounce", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }
    //Universal Scale
    var sm_e_scale = $(".scroll-scale");
    if (sm_e_scale.length) {
        sm_e_scale.each(function() {
            var sm_s_scale = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", offset: 200 })
                .setTween(TweenMax.staggerFromTo(sm_e_scale, 1, { transform: 'scale(0)' }, { transform: 'scale(1)' }, 0))
                .addTo(sm_cont);
            if (DEVMODE) { sm_s_scale.addIndicators({ name: "scroll-scale", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }

    //universal parallax
    var sm_e_parallax = $(".section-parallax");
    if (sm_e_parallax.length) {
        sm_e_parallax.each(function() {
            var sm_s_parallax = new ScrollMagic.Scene({ triggerElement: this, triggerHook: "onEnter", duration: "200%" })
                .setTween(TweenMax.to(sm_e_parallax.children(".p_image"), 1, { y: '-40%', autoAlpha: 1, ease: Power0.easeNone }))
                .addTo(sm_cont);
            if (DEVMODE) { sm_s_parallax.addIndicators({ name: "parallax", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
        });
    }

    //Homepage
    //section text
    var tl_h0 = new TimelineMax({});
    var sm_s_h0 = new ScrollMagic.Scene({ triggerElement: '.section-parallax-bg',
        triggerHook: "onEnter",
        duration: "200%" })
        .setTween(TweenMax.to(sm_e_parallax.children(".bg-wrap").children(".bg"), 1, {
            y: '-20%',
            autoAlpha: 1,
            ease: Power0.easeNone
        },{
            y: '5%',
            autoAlpha: 1,
            ease: Power0.easeNone
        }))
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_h0.addIndicators({ name: "listing_wrapper", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };


    //featured Listings
    var tl_h1 = new TimelineMax({});
    tl_h1.add(TweenMax.staggerFromTo('.listing_wrapper div.listing_slot', 0.3, { top: "30vw", opacity: "0" }, { top: "0vw", opacity: "1" }, 0.3));
    var sm_s_h1 = new ScrollMagic.Scene({ triggerElement: '.listing_wrapper', triggerHook: "onEnter", offset: 300 })
        .setTween(tl_h1)
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_h1.addIndicators({ name: "listing_wrapper", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };

    //Listbox Links
    var tl_h2 = new TimelineMax({});
    tl_h2.add(TweenMax.staggerFromTo('.listbox li', 0.3, { top: "30vw", opacity: "0" }, { top: "0vw", opacity: "1" }, 0.3));
    var sm_s_h2 = new ScrollMagic.Scene({ triggerElement: '.listbox', triggerHook: "onEnter", offset: 300 })
        .setTween(tl_h2)
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_h2.addIndicators({ name: "listbox", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };

    //recent-posts-plus
    var tl_h3 = new TimelineMax({});
    tl_h3.add(TweenMax.staggerFromTo('.recent-posts-plus li', 0.3, { top: "30vw", opacity: "0" }, { top: "0vw", opacity: "1" }, 0.3));
    var sm_s_h3 = new ScrollMagic.Scene({ triggerElement: '.recent-posts-plus', triggerHook: "onEnter", offset: 300 })
        .setTween(tl_h3)
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_h3.addIndicators({ name: "recent-posts-plus", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };

    //featured Listings
    var tl_h4 = new TimelineMax({});
    tl_h4.add(TweenMax.staggerFromTo('.action_box div.action_slot', 0.3, { top: "30vw", opacity: "0" }, { top: "0vw", opacity: "1" }, 0.3));
    var sm_s_h4 = new ScrollMagic.Scene({ triggerElement: '.action_box', triggerHook: "onEnter", offset: 200 })
        .setTween(tl_h4)
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_h4.addIndicators({ name: "action_box", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };

    //Staff
    var tl_h5 = new TimelineMax({});
    tl_h5.add(TweenMax.staggerFromTo('.staff-list div.staff-wrap', 0.3, { top: "30vw", opacity: "0" }, { top: "0vw", opacity: "1" }, 0.3));
    var sm_s_h5 = new ScrollMagic.Scene({ triggerElement: '.staff-list', triggerHook: "onEnter", offset: 200 })
        .setTween(tl_h5)
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_h5.addIndicators({ name: "staff-list", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };

    //featured Listings
    var tl_h6 = new TimelineMax({});
    tl_h6.add(TweenMax.staggerFromTo('.action-wrap div.action_step', 0.3, { top: "30vw", opacity: "0" }, { top: "0vw", opacity: "1" }, 0.3));
    var sm_s_h6 = new ScrollMagic.Scene({ triggerElement: '.action-wrap', triggerHook: "onEnter", offset: 200 })
        .setTween(tl_h6)
        .addTo(sm_cont);
    if (DEVMODE) { sm_s_h6.addIndicators({ name: "action-wrap", indent: 0, colorStart: "#07ff00", colorEnd: "#ff0000", colorTrigger: "#ff9700" }) };
};
$(document).ready(function() {
    scrolltime();
});